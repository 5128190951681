<!--
 * @Description: 停车场运营管理系统 车辆管理 会员车辆 vipCar
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <el-row class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input placeholder="请输入"
                        v-model="searchForm.plateNumber">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-button type="primary"
                       @click="onSubmit">查 询</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info"
                       @click="resetForm"
                       style="margin-left:17px;">重 置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>会员车辆管理</span>
        <!-- <span class="tableTitleButton topButton">
          <el-button type="success"
                     @click="addParkSpace">新增会员车辆</el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </span> -->
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  :config='tableConfig'
                  @getList="getList"
                  :paginationConfig='paginationConfig'
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="状态"
                             prop='blackWhiteListStatus'>
              <template slot-scope="scope">

                <span :class="memberStatus(scope.row.memberStatus)">
                  {{scope.row.memberStatus == '1'?'生效中':'已失效'}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="outOfCommission(scope.row)"
                               type="text"
                               size="medium">
                      {{scope.row.memberStatus===1?'停用':'启用'}}
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleOrderUpdate(scope.row)">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleOrderDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog :title="this.dialogTitle"
               :visible.sync="dialogVisible"
               append-to-body
               @close="popClose">
      <!-- 新增会员车表单 -->
      <el-form label-width="100px"
               :model="addForm"
               ref="addForm"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="停车场名称"
                          prop="parkId">
              <el-select clearable
                         v-model="addForm.parkId"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌号"
                          prop="plateNumber">
              <el-input v-model="addForm.plateNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          prop="numberPlateColorCode">
              <el-select clearable
                         v-model="addForm.numberPlateColorCode"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员类型"
                          prop="memberType">
              <el-select clearable
                         v-model="addForm.memberType"
                         placeholder="请选择"
                         class="dt-form-width">
                <el-option v-for="(item, index) in memberTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <!-- <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="生效时间"
                          prop="effectiveTime"
                          required>
              <el-date-picker v-model="addForm.effectiveTime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="选择日期时间"
                              class="dt-form-width">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失效时间"
                          prop="expirationTime"
                          required>
              <el-date-picker v-model="addForm.expirationTime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="选择日期时间"
                              class="dt-form-width">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row justify="space-around">
          <el-form-item label="时间"
                        prop="date">
            <el-date-picker v-model="timeList"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="生效时间"
                            end-placeholder="失效时间">
            </el-date-picker>
          </el-form-item>
        </el-row> -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addForm.remark"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClose('addForm')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="dialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </el-row>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
        chargCode: '',
        chargName: '',
        operName: '',
      },
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'plateColor',
          label: '车牌颜色',
        },
        // {
        //   prop: 'effectiveTime',
        //   label: '生效时间',
        // },
        // {
        //   prop: 'expirationTime',
        //   label: '失效时间',
        // },
        {
          prop: 'remark',
          label: '备注',
        },
      ],
      tableList: {
        list: [],
      },
      //停车场列表
      parkNameList: [],
      //搜索框绑定字段
      searchForm: {
        plateNumber: '',//车牌号
        // parkId: '',//停车场
      },
      pageNum: 1,
      pageSize: 15,
      //弹出框表单
      addForm: {
        // plateColor: '', //车牌颜色
        // memberId: '', //会员编号
        // plateNumber: '', //车牌号
        // defaultPlateNumberFlag: 1,
        // memberType: '', //会员类型编码
        // parkId: '', //停车场编号
        // numberPlateColorCode: '', //车牌颜色编码
        // remark: '', //备注
        // effectiveTime: '', //生效时间
        // expirationTime: '', //失效时间
      },
      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框显示
      colorList: [],//车牌颜色下拉框
      memberTypeList: [],//会员类型
      disable: '停用',
      massDeleteList: [],//多选框表格
      // 校验准则
      rules: {
        parkId: [
          { required: true, message: '请选择停车场名称', trigger: 'change' }
        ],
        plateNumber: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
        ],
        numberPlateColorCode: [
          { required: true, message: '请选择车牌颜色', trigger: 'change' }
        ],
        memberType: [
          { required: true, message: '请选择会员类型', trigger: 'change' }
        ],
        // effectiveTime: [
        //   { required: true, message: '请选择日期', trigger: 'change' }
        // ],
        // expirationTime: [
        //   { required: true, message: '请选择日期', trigger: 'change' }
        // ],
        date: [
          { message: '请选择日期', trigger: 'change' }
        ],
      },
      timeList: [],//选择时间数组
      Pager: null,//获取分页器
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1]
    this.initialization()
    this.getQueryDict()
    this.getColorDict()
    this.getVIPState()
  },
  //方法集合
  methods: {
    //获取数据
    initialization () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$vipCar.queryListByPage(this.searchForm).then(response => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      }).catch((error) => {
        console.log('失败', error)
      })
    },
    //查询
    onSubmit () {
      this.Pager.internalCurrentPage = 1
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.initialization()
    },
    //重置表单
    resetForm () {
      this.searchForm = {}
      // 设置pageNum为1
      this.listMap.pageNum = 1
      // 设置序号为1
      this.pageNum = 1
      //设置分页器页面为1
      this.Pager.internalCurrentPage = 1
      this.initialization()
    },

    //新增列表
    add () {
      if (this.timeList.length) {
        this.addForm.effectiveTime = this.timeList[0]
        this.addForm.expirationTime = this.timeList[1]
      } else {
        this.addForm.effectiveTime = ''
        this.addForm.expirationTime = ''
      }
      this.$vipCar.vipCarInsert(this.addForm).then((response) => {
        if (response.resultCode === '2000') {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
        }
        this.initialization()
      })
    },

    //修改列表
    update () {
      if (this.timeList.length) {
        this.addForm.effectiveTime = this.timeList[0]
        this.addForm.expirationTime = this.timeList[1]
      } else {
        this.addForm.effectiveTime = ''
        this.addForm.expirationTime = ''
      }
      this.$vipCar.vipCarUpdate(this.addForm).then((response) => {
        if (response.resultCode === '2000') {
          this.$message({ message: '修改成功', type: 'success' });
        }
        this.initialization()
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.initialization()
    },
    //新增按钮
    addParkSpace () {
      this.addForm = {}
      this.dialogVisible = true
      this.dialogTitle = '新增会员名单'
      this.dialogType = 0
      this.timeList = []
    },
    //弹出框确定按钮
    handleClose (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.add()
          } else if (this.dialogType === 1) {
            this.update()
          }
          this.dialogVisible = false
        } else {
          return false;
        }
      });
    },

    //批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({
          showClose: true,
          message: '至少勾选一条记录',
          type: 'error'
        });
      } else {
        this.$confirm('确认批量删除会员车辆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$vipCar.vipCarDelete(this.massDeleteList).then((response) => {
            if (response.resultCode === '2000') {
              this.$message({
                message: '批量删除成功',
                type: 'success'
              });
            }
            this.initialization()
          }).catch((error) => {
            console.log('失败', error)
          })
        }).catch(() => { })
      }
    },

    //停用记录
    outOfCommission (row) {
      let info = {
        pid: row.pid,
        parkId: row.parkId
      }
      if (row.memberStatus === 1) {
        this.$vipCar.disable(info).then((response) => {
          if (response.resultCode === '2000') {
            this.$message({
              message: '停用成功',
              type: 'success'
            });
            this.initialization()
          }
        })
      } else if (row.memberStatus === 2) {
        this.$vipCar.enable(info).then((response) => {
          if (response.resultCode === '2000') {
            this.$message({
              message: '启用成功',
              type: 'success'
            });
            this.initialization()
          }
        })
      }
    },

    // 修改记录
    handleOrderUpdate (row) {
      this.dialogType = 1
      this.dialogTitle = '会员名单修改'
      this.dialogVisible = true
      this.addForm = this.$deepClone(row)
      this.timeList = [row.effectiveTime, row.expirationTime]
    },

    // 删除记录
    handleOrderDelete (row) {
      console.log(row)
      this.$confirm('确认删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [{
          pid: row.pid,
          parkId: row.parkId
        }]
        this.$vipCar.vipCarDelete(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.initialization()
          }
        })
      }).catch(() => { })
    },

    //车牌颜色筛选方法
    filterTag (value, row) {
      return row.plateColor === value
    },

    //状态变化
    memberStatus (param) {
      let res = ''
      if (param == '1') {
        res = 'effective'
      } else if (param == '2') {
        res = 'effectiveness'
      }
      return res
    },

    //表格多选按钮
    handleChange (val) {
      val.forEach(item => {
        this.massDeleteList.push({
          pid: item.pid,
          parkId: item.parkId
        })
      })
    },
    //获取停车场名称
    getQueryDict () {
      let info = {
        columnName: ["park_id", "park_name"],
        tableName: "tb_park",
        whereStr: [
          {
            colName: "park_type_code",
            value: '2' //这个是停车场类型 1是路内2是路外
          }
        ],
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        let data = response.resultEntity
        this.parkNameList = data
      }).catch((error) => {
        console.log('失败', error)
      })
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.colorList = data
      }).catch((error) => {
        console.log('失败', error)
      })
    },
    //获取会员状态颜色
    getVIPState () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F12D123F219345FKAJS4124912142421F',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.memberTypeList = data
      }).catch((error) => {
        console.log('失败', error)
      })
    },
    // 清空验证
    popClose () {
      this.$refs.addForm.resetFields();
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 80px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}

/deep/.class-name {
  background: 'red';
}
// 最外层el-row
.mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
//生效字颜色
.effective {
  color: #bae8ff;
}
//失效字颜色
.effectiveness {
  color: #f69624;
}
.dt-form-width {
  width: 230px;
}
</style>
